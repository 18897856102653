<template>
  <div class="auctionMangerPage">
    
    <div class="main">
        <div class="titleDiv">
          <span>Level 4 轮播图区域名称</span>
          <div class="titleInput">
            <a-input v-model="bannerAreaDetail.areaName" v-if="!isShowBannerArea" disabled/>
            <a-input v-else disabled/>
          </div>
          <a-button type="primary" class="addBtn" @click="editBannerName"
            >修改</a-button
          >
        </div>
      <div class="top_view">
        <a-button type="primary" class="addBtn" @click="addBannerClick"
          >新增轮播图</a-button
        >
        <div>
          <a-input-search
            class="search_view"
            v-model="searchValue"
            placeholder="请输入Banner名称进行查询"
            enter-button
            allow-clear
            @search="onSearchBanner"
          />
          <a-select
            style="width: 140px; margin-right: 20px;"
            v-model="statusValue"
            placeholder="请选择状态"
            @change="changStatusValue"
          >
            <a-select-option
              v-for="(items, index) in statusList"
              :key="index"
              :value="items.value"
            >
              {{ items.title }}
            </a-select-option>
          </a-select>
<!--          <a-select-->
<!--            style="width: 140px;"-->
<!--            v-model="typeValue"-->
<!--            placeholder="请选择类型"-->
<!--            @change="changTypeValue"-->
<!--          >-->
<!--            <a-select-option-->
<!--              v-for="(items, index) in typeList"-->
<!--              :key="index"-->
<!--              :value="items.value"-->
<!--            >-->
<!--              {{ items.title }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        :scroll="{ x: 1400 }"
        :pagination="false"
        :rowKey="(record) => record.bannerId"
        :loading="tableLoad"
      >
        <template slot="type" slot-scope="text, record">
          <span v-if="record.type === 1">拍卖会</span>
          <span v-if="record.type === 2">拍品</span>
          <span v-if="record.type === 3">资讯</span>
          <span v-if="record.type === 4">活动列表</span>
          <span v-if="record.type === 5">活动详情</span>
        </template>
        <template slot="jumpName" slot-scope="text, record">
          <span v-if="record.jumpName==null">-</span>
          <span v-else>{{record.jumpName}}</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <template v-if="record.status === 1">
            <a-popconfirm
              title="确定禁用该记录吗？小程序端将不可见"
              ok-text="确定"
              cancel-text="取消"
              @confirm="contentStutas(record)"
            >
              <a-tag class="pointer" color="green">
                正常
              </a-tag>
            </a-popconfirm>
          </template>
          <template v-if="record.status === 0">
            <a-popconfirm
              title="确定启用该记录吗？小程序端将可见"
              ok-text="确定"
              cancel-text="取消"
              @confirm="contentStutas(record)"
            >
              <a-tag class="pointer">
                未启用
              </a-tag>
            </a-popconfirm>
          </template>
        </template>
        <template slot="action" slot-scope="text, record,index">
          <div class="action_btn_view">
            <a-space>
              <span v-if="index != 0">
                <a-popconfirm
                        placement="top"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="upFun(record)"
                >
                  <template #title>
                    <p>确定上移该记录吗？</p>
                  </template>
                  <a-button>上移</a-button>
                </a-popconfirm>
              </span>
              <span v-if="index  < tableData.length - 1">
                <a-popconfirm
                        placement="top"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="downFun(record)"
                >
                  <template #title>
                    <p>确定下移该记录吗？</p>
                  </template>
                  <a-button>下移</a-button>
                </a-popconfirm>
              </span>
              <span v-if="index != 0">
                <a-popconfirm
                  placement="top"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="topFun(record)"
                >
                  <template #title>
                    <p>确定置顶该记录吗？</p>
                  </template>
                  <a-button>置顶</a-button>
                </a-popconfirm>
              </span>
              <a-button @click="editorFun(record)">编辑</a-button>
              <span>
                <a-popconfirm
                  placement="top"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="deleteFun(record)"
                >
                  <template #title>
                    <p>确定删除该记录吗？</p>
                  </template>
                  <a-button>删除</a-button>
                </a-popconfirm>
              </span>
            </a-space>
          </div>
        </template>
      </a-table>
       <div class="page_warp">
<!--        <a-pagination-->
<!--          class="pagination"-->
<!--          v-model="page.current"-->
<!--          :total="page.total"-->
<!--          show-less-items-->
<!--          :defaultPageSize="page.size"-->
<!--          @change="pageCurrentChange"-->
<!--        />-->
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <a-modal
      width="75%"
      v-model="isAdd"
      :title="header_title"
      okText="确定"
      cancelText="取消"
      @cancel="cancel"
      :maskClosable="false"
      :keyboard="false"
      @ok="submitData"
    >
      <div class="modal_content">
        <a-form-model
          layout="horizontal"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 14 }"
          :model="formData"
          :rules="rules"
          ref="contentForm"
        >
          <a-form-model-item label="轮播图名称" prop="name">
            <a-input
              v-model="formData.name"
              placeholder="请输入"
              :maxLength="50"
            />
          </a-form-model-item>
<!--          <a-form-model-item label="轮播图类型" prop="location">-->
<!--            <a-select v-model="formData.location" placeholder="请选择类型">-->
<!--              <a-select-option :value="1">-->
<!--                顶部轮播图-->
<!--              </a-select-option>-->
<!--              <a-select-option :value="2">-->
<!--                精选专题-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item-->
<!--            label="轮播图顺序"-->
<!--            prop="sort"-->
<!--            placeholder="请输入"-->
<!--          >-->
<!--            <a-input v-model="formData.sort" :maxLength="50" />-->
<!--            数字越小排序越靠前-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="轮播图图片" prop="images">
            <a-upload
              listType="picture-card"
              class="upload_btn"
              :fileList="coverList"
              :customRequest="uploadCover"
              @preview="handlePreview"
              :remove="delCover"
              accept="image/*"
            >
              <div v-if="coverList.length < 1">
                <a-icon :type="loading ? 'loading' : 'upload'" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="previewCancel"
              title="预览图片">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
            最佳尺寸
            <span v-if="formData.location == 1">750*440</span>
            <span v-if="formData.location == 2">409*409</span>
            ；仅支持BMP/JPG/JPEG/PNG格式图片；图片大小不超过2M；
          </a-form-model-item>
          <a-form-model-item label="跳转类型" prop="type">
            <a-select v-model="formData.type" placeholder="请选择类型">
              <a-select-option :value="1">
                拍卖会
              </a-select-option>
              <a-select-option :value="2">
                拍品
              </a-select-option>
              <a-select-option :value="3">
                资讯
              </a-select-option>
              <a-select-option :value="4">
                活动列表
              </a-select-option>
              <a-select-option :value="5">
                活动详情
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="formData.type !== 4" label="跳转内容" prop="jump">
            <a-space v-if="formData.type === 1 && jumpAuction.length == 0">
              <a-button @click="addJumpContent"
                ><a-icon type="plus" />添加</a-button
              >
            </a-space>
            <a-space v-if="formData.type === 2 && jumpAuctionItem.length == 0">
              <a-button @click="addJumpContent"
                ><a-icon type="plus" />添加</a-button
              >
            </a-space>
            <a-space v-if="formData.type === 3 && jumpArticle.length == 0">
              <a-button @click="addJumpContent"
                ><a-icon type="plus" />添加</a-button
              >
            </a-space>
            <a-space v-if="formData.type === 5 && jumpActivity.length == 0">
              <a-button @click="addJumpContent"
                ><a-icon type="plus" />添加</a-button
              >
            </a-space>
            <div class="content_warp">
              <div class="block_warp">
                <div
                  v-if="formData.type === 1 && jumpAuction.length > 0"
                  class="block_left"
                >
                  <div class="auctions">
                    <div class="auctions_top">
                      <div class="auctions_title">
                        {{ jumpAuction[0].content.auctionName }}
                      </div>
                      <div class="auctions_warp">
                         <img :src="jumpAuction[0].content.img.split(',')[0]" alt="" />
                      </div>
                    </div>
                    <div class="auctions_date">
                      <a-icon type="clock-circle" class="date_icon" />
                      <span class="start_time">{{
                        dateFormat(Number(jumpAuction[0].content.startTime)) +
                          "点"
                      }}</span>
                      <span class="line"> — </span>
                      <span class="end_time">{{
                        dateFormat(Number(jumpAuction[0].content.endTime)) +
                          "点"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.type === 2 && jumpAuctionItem.length > 0"
                  class="block_left"
                >
                  <div class="auctionItems">
                    <div class="pic">
                      <img
                        :src="jumpAuctionItem[0].content.img.split(',')[0]"
                        alt=""
                        v-if="jumpAuctionItem[0].content.img!=null"
                      />
                    </div>
                    <div class="auctionItem_content">
                      <div class="auctionItem_header">
                        <span class="serial">
                          {{ jumpAuctionItem[0].content.number }}
                        </span>
                        <span class="brand">{{
                          jumpAuctionItem[0].content.brand
                        }}</span>
                      </div>
                      <div class="auctionItem_title">
                        {{ jumpAuctionItem[0].content.lotsName }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.type === 3 && jumpArticle.length > 0"
                  class="block_left"
                >
                  <div class="auctionItems">
                    <div class="pic">
                      <img :src="jumpArticle[0].content.cover" alt="" />
                    </div>
                    <div class="auctionItem_content">
                      <div class="auctionItem_title">
                        {{ jumpArticle[0].content.title }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="formData.type === 5 && jumpActivity.length > 0"
                  class="block_left"
                >
                  <div class="auctions">
                    <div class="auctionItems activityName">
                      <div class="pic">
                        <img :src="jumpActivity[0].content.img" alt="" />
                      </div>
                      <div class="auctionItem_content">
                        <div class="auctionItem_title">
                          {{ jumpActivity[0].content.activityName }}
                        </div>
                      </div>
                    </div>
                    <div class="auctions_date">
                      <a-icon type="clock-circle" class="date_icon" />
                      <span class="start_time">{{
                        dateFormat(Number(jumpActivity[0].content.startTime)) +
                          "点"
                      }}</span>
                      <span class="line"> — </span>
                      <span class="end_time">{{
                        dateFormat(Number(jumpActivity[0].content.endTime)) +
                          "点"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="block_right"
                  v-if="formData.type === 1 && jumpAuction.length > 0"
                >
                  <a-space>
                    <a-button @click="addJumpContent"
                      ><a-icon type="redo" />更换</a-button
                    >
                  </a-space>
                </div>
                <div
                  class="block_right"
                  v-if="formData.type === 2 && jumpAuctionItem.length > 0"
                >
                  <a-space>
                    <a-button @click="addJumpContent"
                      ><a-icon type="redo" />更换</a-button
                    >
                  </a-space>
                </div>
                <div
                  class="block_right"
                  v-if="formData.type === 3 && jumpArticle.length > 0"
                >
                  <a-space>
                    <a-button @click="addJumpContent"
                      ><a-icon type="redo" />更换</a-button
                    >
                  </a-space>
                </div>
                <div
                  class="block_right"
                  v-if="formData.type === 5 && jumpActivity.length > 0"
                >
                  <a-space>
                    <a-button @click="addJumpContent"
                      ><a-icon type="redo" />更换</a-button
                    >
                  </a-space>
                </div>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!--    拍卖会弹窗-->
    <auctions
      :isVisible="selectAuctions"
      :auctionsData="auctionsData"
      :loading="auctionsLoading"
      @OnAuctionCancel="OnAuctionCancel"
      @insertClick="insertClick"
      @auctionSearch="auctionSearch"
      @showSizeChangeAuction="showSizeChangeAuction"
    ></auctions>
    <!--    活动弹窗-->
    <activity
      :isVisible="selectActivityModal"
      :activityData="activityData"
      :loading="activityLoading"
      @OnAuctionCancel="OnActivityCancel"
      @insertActivityClick="insertActivityClick"
      @auctionSearch="activitySearch"
      @showSizeChangeAuction="showSizeChangeActivity"
    ></activity>
    <!--    拍品弹窗-->
    <auctions-item
      :isVisible="selectAauctionsItemModal"
      :auctionsItemData="auctionsItemData"
      :loading="auctionsItemLoading"
      @auctionItemInsert="auctionItemInsert"
      @onAuctionItemCancel="onAuctionItemCancel"
      @showSizeChange="showSizeChange"
      @auctionItemSearch="auctionItemSearch"
    ></auctions-item>
    <!--    资讯弹窗-->
    <articles
      :isVisible="selectArticleModal"
      :articlesData="articlesData"
      :loading="articlesLoading"
      @articlesInsert="articlesInsert"
      @onArticlesCancel="onArticlesCancel"
      @articlesSearch="articlesSearch"
      @showSizeChangeArticles = "showSizeChangeArticles"
    ></articles>
    <Model 
      :showModal = 'isBannerName'
      :bannerAreaDetail="bannerAreaDetail" 
      @cancelBanner="cancelBanner"
      @submitDataArea = "submitDataArea"
      />
  </div>
</template>

<script>
import API from "@/request";
import {mixin} from './banner_common'
import moment from "moment";
import auctions from "@/views/content/components/auctions";
import upload from "@/utils/upload";
import AuctionsItem from "@/views/content/components/auctionsItem";
import articles from "@/views/content/components/articles";
import Activity from "@/views/content/components/activity";
import Model from './components/model'
export default {
  components: {
    AuctionsItem,
    auctions,
    articles,
    Activity,
    Model
  },
  mixins:[mixin],
  name: "auctionMangerPage",

    data() {
      return {
          ...this.data()
      }
    },
  methods: {
    data(){
        return{
          isBannerName:false,//轮播图区域名称显示
          bannerAreaDetail:{},//轮播图区域信息
          isShowBannerArea:true,//显示input
          auctionsItemLoading: false, // 控制拍品列表加载效果
          articlesLoading: false, // 控制资讯列表加载效果
          auctionsLoading: false, // 控制拍卖会列表加载效果
          activityLoading: false, // 控制活动列表加载效果
          coverList: [], // 上传轮播图列表
          previewImage: "", // 首页图预览字段
          previewVisible: false, //控制首页图预览显示隐藏字段
          loading: false, // 封面图上传加载效果控制字段
          tableLoad: false, // 表格数据加载效果
          selectAuctions: false, // 选择拍卖会对话框显示隐藏
          selectActivityModal:false,//选择活动列表对话框显示隐藏
          selectAauctionsItemModal: false, // 控制拍品列表显示隐藏
          selectArticleModal: false, // 控制资讯列表显示隐藏
          auctionsData:{ // 拍卖会列表
            size: 10,
            total: 0,
            records:[],
            current : 1
          },
          activityData:{ // 活动列表
            size: 10,
            total: 0,
            records:[],
            current : 1
          },
          auctionsItemData: { // 拍品列表
            size: 10,
            total: 0,
            records:[],
            current : 1
          },
          articlesData: { // 资讯列表
            size: 10,
            total: 0,
            records:[],
            current : 1
          },
          previewData: {}, //手机预览传入的数据
          isSubmitInfo: false, //点击新建可能会重复提交数据

          rules: {
            // 表单验证规则
            type: [
              {
                required: true,
                message: "请选择跳转类型",
                trigger: "change",
              },
            ],
            // location: [
            //   {
            //     required: true,
            //     message: "请选择轮播图类型",
            //     trigger: "change",
            //   },
            // ],
            name: [
              {
                required: true,
                message: "请输入轮播图名称",
                trigger: "blur",
              },
            ],
            // sort: [
            //   {
            //     required: true,
            //     message: "请输入轮播图顺序",
            //     trigger: "blur",
            //   },
            // ],
            images: [
              {
                required: true,
                message: "请上传轮播图",
                trigger: "blur",
              },
            ],
            jump: [
              {
                required: true,
                message: "请选择跳转内容",
                trigger: "blur",
              },
            ],
          },
          jumpAuction: [],
          jumpAuctionItem: [],
          jumpArticle: [],
          jumpActivity: [],
          formData: {
            // 表单数据
            images: "", //轮播图图片
            jump: "", //轮播图跳转信息
            location: this.typeValue, //轮播图位置
            name: "", //轮播图名称
            sort: "", //轮播图序号
            type: 1, //轮播图跳转类型
          },
          page: {
            // 表格分页相关
            total: 0,
            current: 1,
            size: 100,
          },
          tableData: [], // 表格数据
          changStatus: "", // 表格右上类型筛选绑定字段
          searchValue: "",
          statusList: [
            {
              value: "",
              title: "全部状态",
            },
            {
              value: "1",
              title: "正常",
            },
            {
              value: "0",
              title: "未启用",
            },
          ], //搜索状态
          statusValue: "",
          typeList: [
            {
              value: "",
              title: "全部类型",
            },
            {
              value: "1",
              title: "顶部轮播图",
            },
            {
              value: "2",
              title: "精选专题",
            },
          ], //搜索状态
          typeValue: 0,
          columns: [
            {
              title: "Banner名称",
              dataIndex: "name",
              key:'name'
            },

            // {
            //   title: "Banner类型",
            //   dataIndex: "location",
            //   scopedSlots: { customRender: "location" },
            // },
            {
              title: "展示顺序",
              dataIndex: "sort",
              key:'sort',
              width:120,
              customRender: (text,record,index) => {

                return index + 1;
              },
            },
            {
              title: "跳转类型",
              dataIndex: "type",
              key:'type',
              scopedSlots: { customRender: "type" },
              width:130,
            },
            {
              key:'jumpName',
              title: "跳转内容名称",
              dataIndex: "jumpName",
              scopedSlots: { customRender: "jumpName" },
              //  customRender: (text, record) => {
              //  if (record.jumpName == "" || record.jumpName == null) {
              //     return <span>-</span>;
              //   }
              // },
            },
            {
              title: "状态",
              dataIndex: "status",
              key:'status',
              width:100,
              scopedSlots: { customRender: "status" },
            },
            {
              title: "修改日期",
              dataIndex: "updateTime",
              key:'updateTime',
              width:120,
              customRender: (record) => {
                let aFormat = moment(parseInt(record)).format("YYYY/MM/DD");
                return aFormat;
              },
            },
            {
              title: '操作',
              key: 'operation',
              width:400,
              scopedSlots: { customRender: 'action' },
            },
          ],
          isAdd: false, // 控制新增表单弹窗控制字段
          header_title: "新增拍品",
          isShowPriview: false,
        }
    },
     resetData(){
      Object.assign(this.$data ,this.data());
    },
     pageCurrentChange(val) {
      // 分页页码变化
      this.page.current = val;
      this.getList();
    },
    //状态搜索
    changStatusValue(value) {
      this.statusValue = value;
      this.getList();
    },
    //类型搜索
    // changTypeValue(value) {
    //   this.typeValue = value;
    //   this.getList();
    // },
    imgPreviewCancel() {
      this.isImgPreview = false;
    },
    dateFormat(val) {
      return moment(val).format("YYYY年MM月DD日 a HH");
    },
    addJumpContent() {
      if (this.formData.type == "1") {
        this.selectAauctions();
      }
      if (this.formData.type == "2") {
        this.selectAauctionsItem();
      }
      if (this.formData.type == "3") {
        this.selectArticles();
      }
      if (this.formData.type == "5") {
        this.selectActivity();
      }
    },
    // 选择拍品
    selectAauctionsItem() {
      this.selectAauctionsItemModal = true;
      this.auctionsItemLoading = true;
      this.getAuctionItemsList();
    },
    // 关闭选择拍品列表
    onAuctionItemCancel() {
      this.selectAauctionsItemModal = false;
      this.auctionsItemLoading = false;
      this. auctionsItemData = {
            size: 10,
            total: 0,
            records:[],
            current : 1
      }
    },
    auctionItemInsert(val) {
       if(val==""){
        this.$message.warning('请选择要插入的内容')
         return;
      }
      // 接收拍品插入内容的Id
      let res = this.auctionsItemData.records.find((item) => {
        return item.lotsId === val;
      });
      this.jumpAuctionItem = [];
      this.jumpAuctionItem.push({
        type: 2,
        content: res,
      });
      this.selectAauctionsItemModal = false;
      this.auctionsItemLoading = false;
      this. auctionsItemData = {
        size: 10,
        total: 0,
        records:[],
        current : 1
      }
    },
    selectAauctions() {
      // 选择拍卖会
      this.selectAuctions = true;
      this.auctionsLoading = true;
      this.getAuctionOrlots();
    },
    selectActivity(){
      // 选择活动
      this.selectActivityModal = true;
      this.activityLoading = true;
      this.getActivityList();
    },
    OnActivityCancel(){
      // 关闭拍卖会列表
      this.selectActivityModal = false;
      this.activityLoading = false;
      this.activityData = {
        size: 10,
        total: 0,
        records:[],
        current : 1
      };
    },
    OnAuctionCancel() {
      // 关闭拍卖会列表
      this.selectAuctions = false;
      this.auctionsLoading = false;
      this.auctionsData = {
        size: 10,
        total: 0,
        records:[],
        current : 1
      };
    },
    insertClick(val) {
      // 接收拍卖会插入内容的下标
      if(val==""){
        this.$message.warning('请选择要插入的内容')
         return;
      }
      let res = this.auctionsData.records.find((item) => {
        return item.auctionId === val;
      });
      this.jumpAuction = [];
      this.jumpAuction.push({
        type: 1,
        content: res,
      });
      this.selectAuctions = false;
      this.auctionsLoading = false;
      this.auctionsData = {
        size: 10,
        total: 0,
        records:[],
        current : 1
      };
    },
    // 接收活动插入内容的下标
    insertActivityClick(val) {
      if(val==""){
        this.$message.warning('请选择要插入的内容')
         return;
      }
      let res = this.activityData.records.find((item) => {
        return item.id === val;
      });
      this.jumpActivity = [];
      this.jumpActivity.push({
        type: 5,
        content: res,
      });

      this.selectActivityModal = false;
      this.activityLoading = false;
      this.activityData = {
        size: 10,
        total: 0,
        records:[],
        current : 1
      };
    },
    selectArticles() {
      // 选择资讯
      this.selectArticleModal = true;
      this.articlesLoading = true;
      this.getAllArticlesList();
    },
    // 关闭选择资讯列表
    onArticlesCancel() {
      this.selectArticleModal = false;
      this.articlesLoading = false;
      this.articlesData = {
        size: 10,
        total: 0,
        records:[],
        current : 1
      };
    },
    articlesInsert(val) {
       if(val==""){
        this.$message.warning('请选择要插入的内容')
         return;
      }
      // 接收资讯插入内容的Id
      let res = this.articlesData.records.find((item) => {
        return item.contentId === val;
      });
      this.jumpArticle = [];
      this.jumpArticle.push({
        type: 3,
        content: res,
      });

      this.selectArticleModal = false;
      this.articlesLoading = false;
      this.articlesData = {
        size: 10,
        total: 0,
        records:[],
        current : 1
      };
    },
    /**
     *  新增模块类型  type   1 拍卖会 2 拍品 3资讯
     * */
    /**
     * 轮播图预览
     */
    handlePreview() {
      // 轮播图预览方法
      this.previewVisible = true;
      this.previewImage = this.coverList[0].url;
    },
    previewCancel() {
      // 轮播图取消预览
      this.previewVisible = false;
      this.previewImage = "";
    },
    // 轮播图上传
    uploadCover(action) {
      const file = action.file;
      if (
        file.type == "image/png" ||
        file.type == "image/bmp" ||
        file.type == "image/jpeg" ||
        file.type == "image/jpg"
      ) {
        let size = file.size / 1048576;

        if (size > 2) {
          this.$message.warning("图片文件不能超过2M");
          return;
        }
        let pathName =
          "content/" +
          new Date().valueOf() +
          parseInt(Math.random() * 10000) +
          file.name;
        this.loading = true;
        upload.uploadFile(pathName, file).then((res) => {
          this.loading = false;
          this.coverList.push({
            uid: "-1",
            name: "image.png",
            status: "done",
            url: res,
          });
          this.formData.images = this.coverList[0].url;
        });
      } else {
        this.$message.warning("不支持的文件格式");
      }
    },
     /**
     * 修改轮播图名称
     */
    editBannerName(){
      this.header_title = "设置轮播图区域名称";
      this.isBannerName = true;
    },
    /**
     * 新增轮播图
     */
    addBannerClick() {
      this.header_title = "新增轮播图";
      this.cancel();
      this.isAdd = true;
    },
    delCover() {
      // 删除轮播图
      this.coverList = [];
      this.formData.images = [];
    },
    /**
     * 删除轮播图
     */
    async deleteFun(item) {
      let res = await API.deleteBanner({ bannerId: item.bannerId });

      if (res.data.code === "0000") {
        this.$message.success("删除成功");
        this.getList();
      }
    },
    /**
     * 编辑轮播图
     */
    async editorFun(record) {
      this.header_title = "编辑轮播图";
      this.isAdd = true;
      this.contentLoading = true;
      this.jumpAuction = [];
      this.jumpAuctionItem = [];
      this.jumpArticle = [];
      this.jumpActivity = []
      let res = await API.getBannerById({ bannerId: record.bannerId });

      if (res.data.code === "0000") {
        // 组装数据
        let base = res.data.data.base;
        let detail = res.data.data.detail;
        this.coverList = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: base.images,
          },
        ];
        //
        let data = {
          images: base.images, //轮播图图片
          jump: "", //轮播图跳转信息
          location: base.location, //轮播图位置
          name: base.name, //轮播图名称
          sort: base.sort, //轮播图序号
          type: base.type, //轮播图跳转类型
          bannerId: base.bannerId,
        };

        if (base.type == 1) {
          this.jumpAuction.push({
            type: 1,
            content: detail,
          });
          data.jump = detail.auctionId; //this.jumpAuction;
        }
        if (base.type == 2) {
          this.jumpAuctionItem.push({
            type: 2,
            content: detail,
          });
          data.jump = detail.lotsId; //this.jumpAuctionItem;
        }
        if (base.type == 3) {
          this.jumpArticle.push({
            type: 3,
            content: detail,
          });
          data.jump = detail.contentId; //this.jumpArticle;
        }
        if(base.type == 5){
          this.jumpActivity.push({
            type: 5,
            content: detail,
          });
          data.jump = detail.id; //this.jumpArticle;
        }

        this.contentLoading = false;
        this.formData = data;
      }
    },
    //名称搜索 轮播图
    onSearchBanner(value) {
      this.getList();
    },    
    /**
     * 弹出框提交数据
     */
    async submitData(val) {
      let validateJump;
      if (this.formData.type == 1 && this.jumpAuction.length > 0) {
        validateJump = this.jumpAuction[0].content.auctionId;
      }
      if (this.formData.type == 2 && this.jumpAuctionItem.length > 0) {
        validateJump = this.jumpAuctionItem[0].content.lotsId;
      }
      if (this.formData.type == 3 && this.jumpArticle.length > 0) {
        validateJump = this.jumpArticle[0].content.contentId;
      }
      if (this.formData.type == 5 && this.jumpActivity.length > 0) {
        validateJump = this.jumpActivity[0].content.id;
      }
       if (this.formData.type == 4 ) {
        validateJump = 'activity-list';
      }
      this.formData.jump = validateJump;

      // 组装提交数据
      this.$refs.contentForm.validate(async (valid) => {
        if (valid) {
          let res;
          let tempJump;
          if (this.formData.type == 1) {
            tempJump = this.jumpAuction[0].content.auctionId;
          }
          if (this.formData.type == 2) {
            tempJump = this.jumpAuctionItem[0].content.lotsId;
          }
          if (this.formData.type == 3) {
            tempJump = this.jumpArticle[0].content.contentId;
          }
          if (this.formData.type == 4) {
            tempJump = 'activity-list';
          }
          if (this.formData.type == 5) {
            tempJump = this.jumpActivity[0].content.id;
          }

          let message;

          if (!this.formData.bannerId) {
            if (this.isSubmitInfo) {
              // this.$message.warning("新增中，请勿重复提交！");
              return;
            }
            this.isSubmitInfo = true;
            this.formData.jump = tempJump;
            res = await API.addBanner(this.formData).catch((err) => {
              this.isSubmitInfo = false;
            });
            message = "新增成功";
          } else {
            this.formData.jump = tempJump;
            res = await API.editBanner(this.formData);
            message = "修改成功";
          }
          if (res.data.code === "0000") {
            this.$message.success(message);
            this.isAdd = false;
            this.formData = {
              images: "",
              jump: "",
              location: this.typeValue,
              name: "",
              sort: "",
              type: 1,
            };
            this.coverList = [];
            this.getList();
          }
        }
      });
    },
    /**
     * 轮播图区域配置提交
     */
    async submitDataArea(val){
      let requestData = {
        areaName: val.areaName,
        status: val.status,
        location: this.typeValue
      }
      let res = await API.addBannerArea(requestData);
      if(res.data.code === '0000'){
        this.$message.success('修改成功');
        this.isBannerName = false
        this.getBannerArea()
      }
    },
    handleChange() {},
    async contentStutas(record) {
      let value = record.status === 1 ? 0 : 1;
      let res = await API.updateBannerStatus({
        bannerId: record.bannerId,
        status: value,
      });
      if (res.data.code === "0000") {
        this.getList();
      }
    },
    cancel() {
      this.selectAuctions = false;
      if (this.$refs.contentForm != undefined) {
        this.$refs.contentForm.resetFields();
      }
      this.formData = {
        // 表单数据
        images: "", //轮播图图片
        jump: "", //轮播图跳转信息
        location: this.typeValue, //轮播图位置
        name: "", //轮播图名称
        sort: "", //轮播图序号
        type: 1, //轮播图跳转类型
      };
      this.coverList = [];
      this.jumpAuction = [];
      this.jumpAuctionItem = [];
      this.jumpArticle = [];
      this.isSubmitInfo = false;
    },
    cancelBanner(){
      this.isBannerName = false
      let data = sessionStorage.getItem('bannerAreaDetail') || ''
      this.bannerAreaDetail =JSON.parse(data) 
    },
    auctionItemSearch(val) {
      // 拍品搜索
      this.searchKey = val;
      this.getAuctionItemsList(val);
    },
    auctionSearch(val) {
      // 拍卖会搜索
      this.getAuctionOrlots(val);
    },
    activitySearch(val){
      //活动搜索
      this.getActivityList(val)
    },
    articlesSearch(val) {
      // 资讯搜索
      this.getAllArticlesList(val);
    },
    //拍卖会分页处理
    showSizeChangeAuction(current,size){
      this.getAuctionOrlots(this.searchKey,current)
    },
    //活动分页处理
    showSizeChangeActivity(current,size){
      this.getActivityList(this.searchKey,current)
    },
    //拍品分页处理
    showSizeChange(current,size){
      this.getAuctionItemsList(this.searchKey,current)
    },
    //资讯分页处理
    showSizeChangeArticles(current,size){
      this.getAllArticlesList(this.searchKey,current)
    },
    //获取全部拍品列表
    async getAuctionItemsList(keyWord,current) {
      this.auctionsItemLoading = true;
      let res = await API.auctionOrlots({
        keyWord,
        type: 2,
        current: current?current:1,
        size: 10,
      });
      if (res.data.code === "0000") {
        this.auctionsItemData = res.data.data;
        this.auctionsItemLoading = false;
      }
    },
    async getAuctionOrlots(keyWord,current) {
      // 获取全部拍卖会列表
      this.auctionsLoading = true;
      let res = await API.auctionOrlots({
        keyWord,
        type: 1,
        current: current?current:1,
        size: 10,
      });
      if (res.data.code === "0000") {
        this.auctionsData = res.data.data;
        this.auctionsLoading = false;
      }
    },
    async getActivityList(keyWord,current) {
      // 获取全部活动列表
      this.auctionsLoading = true;
      let res = await API.auctionOrlots({
        keyWord,
        type: 4,
        current: current?current:1, 
        size: 10,
      });
      if (res.data.code === "0000") {
        this.activityData = res.data.data;
        this.activityLoading = false;
      }
    },
    //获取全部资讯列表
    async getAllArticlesList(keyWord,current) {
      this.auctionsItemLoading = true;
      let res = await API.auctionOrlots({
        keyWord,
        type: 3,
        current: current?current:1,
        size: 10,
      });
      if (res.data.code === "0000") {
        this.articlesData = res.data.data;
        this.articlesLoading = false;
      }
    },
    // 获取表格数据
    async getList(
      name = this.searchValue,
      status = this.statusValue,
      current = this.page.current,
      size = this.page.size,
      location = this.typeValue
    ) {
      this.tableLoad = true;
      let res = await API.getBannerPageList({
        name,
        status,
        current,
        size,
        location,
      });

      if (res.data.code === "0000") {
        this.tableLoad = false;
        let data = res.data.data;
        this.tableData = data.records;
        this.page = {
          // 设置分页信息
          current: data.current,
          total: data.total,
          size: data.size,
        };
      }
    },
    //获取区域名称
    async getBannerArea(){
      let res = await API.getBannerArea({location:this.typeValue})
      if(res.data.code === '0000'){
        this.bannerAreaDetail = res.data.data
        sessionStorage.setItem('bannerAreaDetail',JSON.stringify(this.bannerAreaDetail))
        if(this.bannerAreaDetail.status === 0){
          this.isShowBannerArea = true
        }else{
          this.isShowBannerArea = false
        }
      }
    }
  },
  mounted() {
    this.getBannerArea()
    this.getList(); // 请求表格数据
  },
};
</script>

<style lang="less" scoped>
/deep/.has-error .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #f5222d !important;
}
.loading_warp {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .loading_text {
    text-align: center;
  }
}
.picView {
  max-width: 350px;
  max-height: 200px;
}
.videoPlater {
  outline: none;
  max-width: 300px;
  max-height: 200px;
}
.pageHead {
  background: white;
}

.main {
  margin: 24px;
  background: #ffffff;
  padding: 24px;
}

.newLotsBtn {
  margin-bottom: 16px;
}
.modal_content {
  max-height: 600px;
  overflow: auto;
}
.search_View {
  width: 230px;
}

.tab_top_view {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.content_warp {
  display: flex;
  justify-content: space-between;
  .block_warp {
    margin-top: 10px;
    // border: 1px solid #cccccc;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    // padding: 15px 10px;
    display: flex;
    .block_left {
      flex: 1;

      .auctionItems {
        border: 1px solid #bbbbbb;
        // width: 383px;
        display: flex;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 6px;
        .pic {
          display: flex;
          align-items: center;
          margin-right: 7px;
          img {
            height: 105px;
            width: 85px;
            border: none;
          }
        }
        .auctionItem_content {
          .auctionItem_header {
            font-size: 13px;
            line-height: 18px;
            margin-top: 8px;
            .serial {
              color: #919298;
              margin-right: 14px;
            }
            .brand {
              color: #101010;
            }
          }
          .auctionItem_title {
            line-height: 18px;
            margin-top: 8px;
            color: #101010;
          }
        }
      }
      .activityName{
        background: #f7f0f0;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    .block_right {
      box-sizing: border-box;
      padding-left: 25px;
      display: flex;
      //align-items: flex-end;
    }
  }
}
.auctions {
  width: 468px;
  .auctions_top {
    box-sizing: border-box;
    padding: 0 13px;
    background: #f7f0f0;
    border: 1px solid #bbbbbb;
    .auctions_title {
      font-weight: 600;
      color: #000000;
      font-size: 18px;
      magrin-bottom: 7px !important;
    }
    .auctions_warp {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      padding-bottom: 20px;
      img {
        display: block;
        height: 116px;
        width: 107px;
        background: #ffffff;
      }
      &:after {
        content: "";
        height: 0;
        width: 107px;
      }
    }
  }
  .auctions_date {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #101010;
    padding: 0 13px;
    box-sizing: border-box;
    border: 1px solid #bbbbbb;
    border-top: none;
    .date_icon {
      margin-right: 6px;
      font-size: 18px;
      color: #0090fa;
    }
    .line {
      margin: 0 7px;
    }
  }
}
.top_view {
  display: flex;
  justify-content: space-between;
  height: 48px;
}
.search_view {
  width: 280px;
  margin-right: 20px;
}
.page_warp {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.titleDiv{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span{
    font-size: 16px;
    color:#333333;
    font-weight: 600;
    margin-right: 10px;
  }
  .titleInput{
    width:307px;
    margin-right:10px;
  }
}
</style>
